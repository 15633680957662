/////////////
import { Cheats } from 'engine/game_libs/utils/cheats';
//////////

import * as Sentry from '@sentry/browser';
import DataStore from 'game_libs/dataStore';

import '@pixi/mixin-cache-as-bitmap';
import '@pixi/unsafe-eval';
import { MainLayer } from 'engine/layers/mainLayer';

import AVAILABLE_ACTIONS_PARSER from 'game_libs/parsing/availableActionsParser';
import BALANCE_PARSER from 'game_libs/parsing/balanceParser';
import CONFIG_PARSER from 'game_libs/parsing/configParser';
import FREE_SPINS_END_PARSER from 'game_libs/parsing/freeSpinsEndParser';
import FREE_SPINS_PROGRESS_PARSER from 'game_libs/parsing/freeSpinsProgressParser';
import FREE_SPINS_START_PARSER from 'game_libs/parsing/freeSpinsStartParser';
import SHOW_PARSER from 'game_libs/parsing/showParser';
import SPIN_PARSER from 'game_libs/parsing/spinParser';
import SYMBOL_OVERRIDE_PARSER from 'game_libs/parsing/symbolOverrideParser';
import SYMBOL_REPLACEMENT_PARSER from 'game_libs/parsing/symbolReplacementParser';
import WIN_PARSER from 'game_libs/parsing/winParser';

import DataTypes from 'game_libs/dataTypes';
import { GameBus } from 'game_libs/gameBus';
import { SetVersion } from 'game_libs/utils/setVersion';

import { AUDIO_CONFIG } from './config/audioConfig';
import { GameStateMachine, GAME_STATES } from 'engine/state/gameStateMachine';

import FreeSpinDataTypes from 'game_libs/freeSpinDataTypes';
import '@pixi/mixin-get-global-position';

import Game from 'game_libs/game';
import SlotDataTypes from 'game_libs/parsing/dataTypes/slotDataTypes';
import { Audio } from 'game_libs/utils/audio';
import CUSTOM_FREE_SPINS_PROGRESS_PARSER from 'engine/parsing/customFreeSpinsProgressParser';
import { FillPaytable } from 'game_libs/utils/fillPaytable';
import CUSTOM_WILD_PLACEMENT_PARSER from 'engine/parsing/customWildPlacementParser';
import { getUrlParam } from 'game_libs/utils/gtUrlParams';

/////////////
console.info(`🪿 ${process.env.npm_package_name} ${process.env.npm_package_version} DEBUG`);
/////////
//////////////////////////////////////////////////////////////////////////////////////
//////////

declare const EventManager: any;

declare const MagicUI: any;

export const gameId = getUrlParam('gameId');

/////////////
export const cheats = new Cheats({
    'Wild win': [2, 34, 6, 0, 5, 0, 0, 0, 0, 0, 0, 0, 999, 0, 0, 0, 0, 0, 0, 0],
    'Big Win': [2, 34, 5, 0, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    'Mega Win': [50, 20, 54, 13, 6, 0, 999, 999, 0, 0, 0, 0, 0, 0, 999, 0, 0, 0, 0],
    'Epic Win': [0, 0, 0, 0, 0, 999, 999, 999, 999, 999, 999, 999, 999, 999, 999, 999, 999, 999],
    '8 Free Spins': [49, 60, 39],
    'FS (while in FS)': [12, 0, 8],

    Paylines: {
        'Payline 1': [2, 34, 5, 0, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 2': [3, 35, 6, 1, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 3': [1, 33, 4, 49, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 4': [3, 68, 4, 4, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 5': [1, 37, 28, 37, 28, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 6': [2, 0, 6, 5, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 7': [8, 33, 26, 11, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 8': [3, 0, 5, 8, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 9': [7, 33, 27, 13, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 10': [2, 33, 27, 13, 29, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 11': [1, 0, 4, 5, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 12': [3, 34, 28, 37, 30, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 13': [1, 34, 4, 4, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 14': [3, 34, 27, 12, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 15': [7, 34, 27, 0, 28, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 16': [2, 37, 6, 9, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 17': [2, 37, 26, 12, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 18': [1, 0, 15, 5, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 19': [3, 36, 27, 36, 30, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        'Payline 20': [3, 36, 6, 36, 30, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
});

/////////

//////////////////////
/////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
///////////////////
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////
//////

//////////////////////////////////////////////////////////////////////////////
////////////////////////
//////////////////////////
///

//////////

export const game = new Game(
    {
        parseConfig: {
            availableActionsParsers: [AVAILABLE_ACTIONS_PARSER],
            balanceParsers: [BALANCE_PARSER],
            configParsers: [CONFIG_PARSER],
            freeSpinsStartParsers: [FREE_SPINS_START_PARSER],
            freeSpinsProgressParsers: [
                CUSTOM_FREE_SPINS_PROGRESS_PARSER,
                FREE_SPINS_PROGRESS_PARSER,
            ],
            freeSpinsEndParsers: [FREE_SPINS_END_PARSER],
            showParsers: [SHOW_PARSER],
            spinParsers: [SPIN_PARSER],
            symbolOverrideParsers: [
                SYMBOL_OVERRIDE_PARSER,
                SYMBOL_REPLACEMENT_PARSER,
                CUSTOM_WILD_PLACEMENT_PARSER,
            ],
            winParsers: [WIN_PARSER],
        },
        assetManifest: 'assets/assets-manifest.json',
        audioConfig: AUDIO_CONFIG,
        features: {
            payLines: true,
            placedWilds: true,
            bonusSymbol: false,
            freeSpins: true,
            cascade: false,
            logoSeparated: true,
            separatedBackground: true,
        },
    },
    async (game) => {
        document.getElementById('action').classList.add('preload');
        document.getElementById('main-ui').classList.add('preload');

        await SetVersion();

/////////////////////
        cheats.init(game.pixiApp);
//////////////////

        GameBus.on('loadProgress', setLoadingProgress);
    },
    async (game: Game) => {
        DataStore.set(DataTypes.CAN_STOP, true);

        const root = new MainLayer();

        game.addScreen(root, 0);
        FillPaytable(DataStore.get(DataTypes.TOTAL_BET));

        MagicUI.show('Jewel of the Nile');
    },
);

EventManager.on('magic-ui:action', (bet: any) => {
    DataStore.set(DataTypes.CAN_STOP, false);
    if (!DataStore.get<boolean>(SlotDataTypes.CASCADING)) {
        GameBus.emit('spinStart');
        EventManager.emit('disable-ui', {});
        if (!DataStore.get(FreeSpinDataTypes.IS_ACTIVE) && !MagicUI.bonusBetActive()) {
            MagicUI.setBalance(DataStore.get<number>(DataTypes.BALANCE) - bet.bet);
        }
    }
});

EventManager.on('magic-ui:bet-changed', (newBet: number) => {
    GameBus.emitCustom('bet-change', newBet);
    FillPaytable(newBet);
});

EventManager.on('magic-ui:show-game', async () => {
    GameBus.emit('gameStart');
    document.getElementById('action').classList.remove('preload');
    document.getElementById('main-ui').classList.remove('preload');

    switch (DataStore.get(DataTypes.STATE)) {
        case 'MAIN':
            GameStateMachine.enter(GAME_STATES.MAIN);
            Audio.play('Main', true, 0.35);
            Audio.stop('Free Spins');
            break;
        case 'FREESPINS':
            GameStateMachine.enter(GAME_STATES.FREE_SPINS);
            Audio.play('Free Spins', true, 0.35);
            Audio.stop('Main');
            break;
        default:
            throw new Error(`No matching state ${DataStore.get(DataTypes.STATE)}`);
    }
});

function setLoadingProgress(progress: number) {
    if (isNaN(progress) || progress < 0 || progress > 100) {
        return;
    }

    const path = document.getElementById('animated-path') as any;
    const pathLength = path.getTotalLength();

    if (!path || !pathLength) {
        return;
    }

    let offset = pathLength * (1 - progress / 100);

    offset = Math.max(0, offset); // Ensure zero offset for 100%

    if (progress === 100) {
        path.style.strokeDasharray = 0;
    } else {
        path.style.strokeDasharray = pathLength;
        path.style.strokeDashoffset = offset;
    }
}
